import * as React from 'react';
import { graphql } from 'gatsby';
import { Layout, Calendar, Appointments } from '../../components';
import * as moment from 'moment';
import styles from './styles/index.module.scss';
import { Appointment } from '../../components/Appointments/Appointments';

export interface GatsbyLocation extends Location {
  state: { [key: string]: string };
}

export interface PageProps {
  location: GatsbyLocation;
  data: any;
  pageContext: any;
}



const CalendarPage: React.FunctionComponent<PageProps> = (props) => {
  const { markdownRemark } = props.data;
  const { frontmatter } = markdownRemark;
  const [selectedDate, setSelectedDate] = React.useState('');

  let groupedAppointments: any = {};

  const addEntry = (key: string, new_entry: Appointment) => {
    if (key in groupedAppointments) {
      groupedAppointments[key].push({
        ...new_entry,
        number_of_days: undefined,
        date: moment(new_entry.date).format(),
      });
    } else {
      groupedAppointments[key] = [
        {
          ...new_entry,
          number_of_days: undefined,
          date: moment(new_entry.date).format(),
        },
      ];
    }
  };

  frontmatter.entries.forEach((entry: Appointment) => {
    let key = moment(entry.date).format('YYYY-MM-DD');

    addEntry(key, entry);
    while (entry.number_of_days && Number(entry.number_of_days.toString()) > 1) {
      entry.number_of_days = Number(entry.number_of_days.toString()) - 1;
      entry.date = moment(entry.date).add(1, 'day').format();
      key = moment(entry.date).format('YYYY-MM-DD');
      addEntry(key, entry);
    }
  });

  return (
    <Layout locale={props.pageContext.locale} location={props.location}>
      <main>
        <section className={styles.calendarWrapper}>
          <Calendar
            weekdays_short={frontmatter.weekdays_short}
            months={frontmatter.months}
            appointments={groupedAppointments}
            onDateSelect={(dateString) => setSelectedDate(dateString || '')}
          />

          <Appointments
            appointments={groupedAppointments[selectedDate]}
            no_appointments={frontmatter.no_appointments}
            another_day={frontmatter.another_day}
          />
        </section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query($filepath: String!) {
    markdownRemark(fileAbsolutePath: { eq: $filepath }) {
      html
      frontmatter {
        title
        short_description
        type
        nav_menu
        component
        no_appointments
        another_day
        months
        weekdays_short
        entries {
          type
          date
          duration
          location
          image
          number_of_days
        }
      }
    }
  }
`;

export default CalendarPage;
